<template>
  <a-card :bordered="false">
    <a-row :gutter="16" class="m-b-20">
      <a-col :span="6">
        <a-card>
          <div class="s_flex_bian_c">
            <p>累计分账金额 <a-tooltip placement="topRight">
                <template #title>
                  累计分账金额统计所有品牌商分账中、分账失败、分账成功的总金额
                </template>
                <ExclamationCircleOutlined />
              </a-tooltip>
            </p>
            <a-button type="link" @click="brandId='';showIncome=true;status='total'">查看分账明细</a-button>
          </div>
          <p class="s_font_b fz-30 s_c000">{{dataAll.all_balance || 0.00}}</p>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card>
          <div class="s_flex_bian_c">
            <p>可提现金额 <a-tooltip placement="topRight">
                <template #title>
                  可提现金额只统计所有分账成功的总金额
                </template>
                <ExclamationCircleOutlined />
              </a-tooltip>
            </p>
            <a-button type="link" @click="brandId='';showIncome=true;status='success'">查看分账明细</a-button>
          </div>
          <p class="s_font_b fz-30 s_c000">{{dataAll.all_can_withdraw_balance || 0.00}}</p>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card>
          <div class="s_flex_bian_c">
            <p>已提现金额
            </p>
            <a-button type="link" @click="brandId='';showWithdrawal=true">查看提现明细</a-button>
          </div>
          <p class="s_font_b fz-30 s_c000">{{dataAll.all_withdraw_balance || 0.00}}</p>
        </a-card>
      </a-col>

    </a-row>
    <a-form ref="form" :model="search" :style="{ marginBottom: '20px' }" :initialValues="search" :onFinish="pageSearchChange" layout="inline">
      <a-form-item label="关键词" name='keyword'>
        <a-input style="width: 270px" v-model:value="search.keyword" placeholder="请输入品牌商编号/公司名称" />
      </a-form-item>
      <a-form-item label="状态" name="">
        <a-select v-model:value="search.status" :style="{ width: '180px' }" placeholder="全部">
          <a-select-option value="">全部</a-select-option>
          <a-select-option :value="0">待审核</a-select-option>
          <a-select-option :value="1">审核通过</a-select-option>
          <a-select-option :value="2">审核失败</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" htmlType="submit">搜索</a-button>
      </a-form-item>
    </a-form>
    <a-table bordered :data-source="dataSource" :columns="columns" :pagination="false" rowKey="brand_id" :loading="listLoading">
      <template v-slot:balance>累计分账金额
        <a-tooltip placement="topRight">
          <template #title>
            累计分账金额统计所有品牌商所有分账中、分账失败、分账成功的总金额
          </template>
          <ExclamationCircleOutlined />
        </a-tooltip>
      </template>
      <template #balanceContent="{text}">
        {{text}}
      </template>
      <template v-slot:action="{ record }">
        <a-dropdown>
          <a class="ant-dropdown-link"> 操作
            <DownOutlined />
          </a>
          <template v-slot:overlay>
            <a-menu>
              <a-menu-item>
                <a href="javascript:;" @click="can_withdraw_balance=record.can_withdraw_balance;brandId=record.brand_id;balanceVisible=true">提现</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;" @click="brandId=record.brand_id;showIncome=true">分账明细</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;" @click="brandId=record.brand_id;showWithdrawal=true">提现明细</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-table>
    <a-pagination :style="{ marginTop: '20px', textAlign: 'right' }" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
  </a-card>
  <a-modal :keyboard="false" title="提现明细" centered v-model:visible="showWithdrawal" :width='1200' :footer="null" destroyOnClose>
    <withdrawal :id="brandId"></withdrawal>
  </a-modal>
  <a-modal :keyboard="false" title="分账明细" centered v-model:visible="showIncome" :width='1200' :footer="null" destroyOnClose>
    <income :id="brandId" :status="status"></income>
  </a-modal>
  <a-modal :keyboard="false" title="提现" v-model:visible="balanceVisible" centered @ok="handleGet" :confirm-loading="confirmLoading">
    <a-form>
      <a-form-item label="提现金额" :label-col="{span:4}" :wrapper-col="{span:18}">
        <a-input-number class="width100" v-model:value="money" placeholder="请输入提现金额" :precision="2" :min="0" :max="can_withdraw_balance" />
      </a-form-item>
      <a-form-item label="可提现金额" :label-col="{span:4}" :wrapper-col="{span:18}">
        <span style="color:#f04635">{{can_withdraw_balance || '0.00'}}</span>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, reactive, toRefs } from "vue";
import {
  getBanlanceSummary,
  getPlatformBanlance,
  setPlatformCash,
} from "@/api/financial";
import { $iscode } from "@/utils/app";
import { message } from "ant-design-vue";
import withdrawal from "./components/withdrawal.vue";
import income from "./components/income.vue";
let defSearch = {
  keyword: "",
  page: 1,
  limit: 10,
  status: "",
};
export default {
  name: "brandList",
  components: { DownOutlined, withdrawal, income, ExclamationCircleOutlined },
  setup(props) {
    let search = ref({ ...defSearch });
    let total = ref(1);
    const state = reactive({
      status: "",
      dataSource: [],
      listLoading: false,
      showWithdrawal: false,
      showIncome: false,
      dataAll: {},
      brandId: "",
      can_withdraw_balance: "",
      balanceVisible: false,
      money: null,
      confirmLoading: false,
      columns: [
        {
          title: "品牌商编号",
          dataIndex: "brand_id",
        },
        {
          title: "汇付用户ID",
          dataIndex: "ada_member_id",
        },
        {
          title: "公司名称",
          customRender: ({ record }) => {
            return record.brand_name ? record.brand_name : "-";
          },
        },
        {
          title: "状态",
          dataIndex: "status_text",
        },
        {
          dataIndex: "balance",
          key: "balance",
          slots: {
            title: "balance",
            customRender: "balanceContent",
          },
        },
        {
          title: "已提现金额",
          dataIndex: "withdraw_balance",
        },
        {
          title: "可提现金额",
          dataIndex: "can_withdraw_balance",
        },
        {
          title: "操作",
          key: "action",
          width: 120,
          slots: { customRender: "action" },
        },
      ],
    });
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, size) => {
      search.value.page = 1;
      search.value.limit = size;
      initData(search.value);
    };
    //获取总数居
    const getAll = async () => {
      let res = await getBanlanceSummary().then((res) => res.data);
      if ($iscode(res)) {
        state.dataAll = res.data;
      } else {
        message.error(res.message);
      }
    };
    const handleGet = async () => {
      if (!state.money) {
        message.error("请输入提现金额！");
        return;
      }
      state.confirmLoading = true;
      let result = await setPlatformCash({
        money: state.money,
        brand_id: state.brandId,
      })
        .then((res) => res.data)
        .catch((error) => error);
      if ($iscode(result, true)) {
        initData(search.value);
        state.balanceVisible = false;
      }
      state.confirmLoading = false;
    };
    //列表
    const initData = async (values) => {
      state.listLoading = true;
      try {
        let res = await getPlatformBanlance(values).then((res) => res.data);
        state.listLoading = false;
        if ($iscode(res)) {
          state.dataSource = res.data.data;
          total.value = res.data.total;
        } else {
          message.error(res.message);
        }
      } catch (e) {
        state.listLoading = false;
      }
    };
    onMounted(() => {
      initData(search.value);
      getAll();
    });
    return {
      ...toRefs(state),
      initData,
      search,
      total,
      pageSearchChange,
      pageCurrentChange,
      pageSizeChange,
      handleGet,
    };
  },
};
</script>