<template>
  <a-form ref="form" :model="search" :style="{ marginBottom: '20px' }" :initialValues="search" :onFinish="pageSearchChange" layout="inline">
    <a-form-item label="关键词" name='keyword'>
      <a-input style="width: 270px" v-model:value="search.keyword" placeholder="请输入流水号/公司名称" />
    </a-form-item>
    <a-form-item label="创建时间" name="">
      <a-range-picker valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" @change="onChange" />
    </a-form-item>
    <a-form-item label="状态" name="">
      <a-select v-model:value="search.status" :style="{ width: '180px' }" placeholder="全部">
        <a-select-option value="">全部</a-select-option>
        <a-select-option :value="3">提现中</a-select-option>
        <a-select-option :value="1">提现成功</a-select-option>
        <a-select-option :value="2">提现失败</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-button type="primary" htmlType="submit">搜索</a-button>
    </a-form-item>
  </a-form>
  <a-table bordered :data-source="dataSource" :columns="columns" :pagination="false" rowKey="order_no" :loading="listLoading">
  </a-table>
  <a-pagination :style="{ marginTop: '10px', textAlign: 'right' }" show-size-changer show-quick-jumper
    :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange"
    @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
</template>

<script>
  import { ref, onMounted, reactive, toRefs } from "vue";
  import { getPlatformCashLog } from "@/api/financial";
  import { $iscode } from "@/utils/app";
  import { message } from "ant-design-vue";
  let defSearch = {
    keyword: "",
    brand_id:"",
    page: 1,
    limit: 10,
    status: "",
    start_time: "",
    end_time: "",
  };
  export default {
    props: {
      id: Number,
    },
    components: {},
    setup(props) {
      let search = ref({ ...defSearch });
      let total = ref(1);
      const state = reactive({
        dataSource: [],
        listLoading: false,
        columns: [
          {
            title: "流水号",
            dataIndex: "order_no",
          },
          {
            title: "公司名称",
            customRender: ({ record }) => {
              return record.brand_name ? record.brand_name : '-'
            }
          },
          {
            title: "状态",
            dataIndex: "status_text",
          },
          {
            title: "金额",
            dataIndex: "cash_money",
          },
          {
            title: "操作人",
            dataIndex: "action_person_name",
            customRender: ({ record }) => {
              return record.action_person_name ? record.action_person_name : '-'
            }
          },
          {
            title: "操作时间",
            dataIndex: "created_at",
          },
        ],
      });
      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 };
        initData(search.value);
      };
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData(search.value);
      };
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, size) => {
        search.value.page = 1;
        search.value.limit = size;
        initData(search.value);
      };
      //列表
      const initData = async (values) => {
        state.listLoading = true;
        state.dataSource = [];
        try {
          let res = await getPlatformCashLog({...values,brand_id:props.id}).then((res) => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            state.dataSource = res.data.data;
            total.value = res.data.total;
          } else {
            message.error(res.message);
          }
        } catch (e) {
          state.listLoading = false;
        }
      };
      const onChange = (e) => {
        if (e.length > 0) {
          search.value.start_time = e[0];
          search.value.end_time = e[1];
        } else {
          search.value.start_time = "";
          search.value.end_time = "";
        }
      };
      onMounted(() => {
        initData(search.value);
      });
      return {
        ...toRefs(state),
        initData,
        search,
        total,
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        onChange,
      };
    },
  };
</script>